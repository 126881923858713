<template>
    <label class="switch">
        <input
          @input="inputUpdated"
          type="checkbox"
          :checked="value"
          :disabled="disableToggle"
          :readonly="disableToggle"
        />
        <span class="slider round" :class="{'cursor-pointer': !disableToggle}"></span>
    </label>
</template> 

<script>
export default {
    props: {
      value: {
        type: Boolean,
        default: false
      },
      disableToggle: {
        type: Boolean,
        default: false
      }
    },
    methods: {
        inputUpdated(event) {
            this.$emit('input', event.target.checked);
        }
    }
}
</script>

<style scoped>
.switch {
  position: relative;
  display: inline-block;
  margin-bottom: 0;
  width: 47px;
  height: 24px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  width: 47px;
  height: 24px;
  border-radius: 17.5px;
  border: 1px solid #DEE4EB;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #D5DBE1;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 1px;
  bottom: 1px;
  background-color: #fff;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider:before {
  background-color: #FFF;
}
input:checked + .slider {
  background-color: #FC6B2D;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(23px);
  -ms-transform: translateX(23px);
  transform: translateX(23px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 18px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>