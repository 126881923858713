<script>
// import Layout from "../../layouts/main";
// import PageHeader from "@/components/page-header";
// import FilterBtn from "../../../components/filter-btn.vue";
// import PriceForm from './price-form.vue';
import Multiselect from 'vue-multiselect';
import PerPage from '../../../components/per-page.vue';
import SearchBy from '../../../components/search-by.vue';
import switchInput from '../../../components/switch-input.vue';

import Swal from "sweetalert2";

import * as moment from "moment";
import { FETCH_SUBSCRIPTION_PRICE_LIST, DELETE_PRICE } from "@/state/actions.type";

export default {
  components: {
    // Layout,
    // PageHeader,
    // FilterBtn,
    // PriceForm,
    Multiselect,
    SearchBy,
    PerPage,
    switchInput
  },

  props: {
    merchants: {
      type: Array,
      default: () => {
        return []
      },
    },
    showFilters: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      // mor_filter: "",
      isFetchingPriceData: false,
      // isAddPriceModalOpen: false,
      viewDetailsModalOpen:false,
      viewDetailsData:{},
      fields: [
        { key: "created_at", sortable: false, label: "subscription.created_date" },
        { key: "order_ref", sortable: false, label: "subscription.Plan_ID" },
        { key: "name", sortable: false, label: "subscription.Name" },
        { key: "amount", sortable: false, label: "subscription.amount" },
        { key: "period", sortable: false, label: "subscription.period" },
        { key: "frequency", sortable: false, label: "subscription.frequency" },
        { key: "action", sortable: false, label: '' },
        // { key: "created_date", sortable: false, label: "Created Date" },
      ],
      filterOptions: [
        "Frequency",
        "Amount",
        "Plan ID"
      ],
      searchBy: {
        key: "Frequency",
        value: ""
      },
      merchant_uuids: [],
      // currentSelectedFilter: "",
      isFilterApplied: false,
      filters: {
        currency: "",
        period: ""
      },
      cycles: {
        H: "Hour",
        D: "Day",
        W: "Week",
        M: "Month",
        Q: "Quarter",
        Y: "Year"
      },
      cyclesList: [
        { value: "H", key: "Hour" },
        { value: "D", key: "Day" },
        { value: "W", key: "Week" },
        { value: "M", key: "Month" },
        { value: "Q", key: "Quarter" },
        { value: "Y", key: "Year" }
      ],
      currencies: [
        "IDR",
        "PHP",
        "SGD",
        "THB",
        "VND",
        "MYR",
        "TWD",
        "HKD",
        "KHR",
        "WST",
        "USD",
        "INR",
        "AUD",
        "EUR",
        "CAD"
      ],
    };
  },
  filters: {
    date(value) {
        return moment(value).format("DD MMM YYYY");
    },
    time(value) {
        return 'at ' + moment(value).format("hh:mm A");
    },
  },

  watch: {
    perPage() {
      this.fetchPriceList();
    },
    showFilters() {
      this.$root.$emit('bv::toggle::collapse', 'price-filter-collapse')
    },
    merchants(value) {
      this.merchant_uuids = value;
      this.fetchPriceList();
    }
  },
  computed: {
    title() {
      return this.$t('menuitems.plan.text');
    },

    items() {
      return [
        {
          text: this.$t('menuitems.subscriptions.text'),
          active: false,
          href: this.$router.resolve({ name: "Subscription" }).href,
        },
        {
          text: this.$t('menuitems.plan.text'),
          active: true,
        },
      ];
    },

    rows() {
      return this.$store.state.subscriptionPrice.total_elements;
    },

    priceData() {
      return this.$store.state.subscriptionPrice.content;
    },
    isMasterMerchant() {
      return this.$store.state.auth.currentUser.is_master_merchant;
    },
    merchantListDataFromStore() {
      return this.$store.state.masterMerchant.content;
    },
  },

  created() {
    this.fetchPriceList();
  },

  methods: {
    resetAdvanceFilters() {
      this.filters = {
        currency: "",
        period: ""
      };
      this.fetchPriceList();
      this.isFilterApplied = false;
    },

    fetchPriceList() {
      let filters = {};
      if(this.searchBy.value && this.searchBy.key) {
        if(this.searchBy.key === "Plan ID") {
          filters.order_ref = this.searchBy.value;
        } else {
          filters[this.searchBy.key.toLowerCase()] = this.searchBy.value;
        }
      }
      if(this.filters.period) {
        filters.period = this.filters.period.value;
      }
      if(this.filters.currency) {
        filters.currency = this.filters.currency;
      }

      let payload = {
        "page": this.currentPage,
        "to": moment(new Date()).format("YYYY-MM-DDTHH:mm:ssZ"),
        "from": "2006-01-02T09:34:05.000Z",
        "pagesize": this.perPage,
        "filters": filters
      };

      if(this.isMasterMerchant) {
        payload.merchant_keys = this.merchant_uuids.map(element => element.key);
      }

      this.$store.dispatch(`subscriptionPrice/${FETCH_SUBSCRIPTION_PRICE_LIST}`, payload)
    },

    // closeCreatePriceModal() {
    //   this.isAddPriceModalOpen = false;
    //   this.fetchPriceList();
    // },

    closeViewDetailsModal() {
      this.viewDetailsModalOpen = false;
    },
    viewDetailsClick(data){
      this.viewDetailsData = data
      this.viewDetailsModalOpen = true
    },

    deletePrice(item) {

      Swal.fire({
        title: "Are you sure you want to delete?",
        text:"Order Ref - "+ item.order_ref,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes, delete it!",
        showClass: {
          backdrop: 'swal2-noanimation', // disable backdrop animation
          popup: '',                     // disable popup animation
          icon: ''                       // disable icon animation
        },
      }).then(result => {
        if (result.value) {
          this.$store
            .dispatch(`subscriptionPrice/${DELETE_PRICE}`, item.order_ref)
            .then((response) => {
              console.log(response);

              this.$notify({
                type: "success",
                text: "Payment Deleted Successfully.",
                closeOnClick: true,
              });

              Swal.fire("Deleted!", "Plan has been deleted.", "success");

              setTimeout(() => {
                this.fetchPriceList();
              }, 500);
            })
            .catch((error) => {
              // error
              console.log("error", error);
              Swal.fire("Deleted!", "Failed To delete Plan.", "error");
            });
        }
      });
    }
  },

  beforeDestroy() {
    this.$store.dispatch("notification/clear");
  },
};
</script>

<template>
  <div>
    <div class="row">
      <div class="col-sm-12 col-md-12">
        <b-collapse id="price-filter-collapse">
          <b-card style="box-shadow: none;border-radius: 15px;border: 1px #DCDAD5 solid !important ;margin-bottom: 15px;" class="border border-light">
            <div class="row mt-3">
              <div class="col-sm-12 col-md-4 col-xl-3">
                <multiselect
                  v-model="filters.period"
                  placeholder="Search Cycle"
                  :searchable="true"
                  :options="cyclesList"
                  label="key"
                  track-by="value"
                ></multiselect>
              </div>
              <div class="col-sm-12 col-md-6 col-xl-3">
                <multiselect
                  v-model="filters.currency"
                  placeholder="Search Currency"
                  :searchable="true"
                  :options="currencies"
                ></multiselect>
              </div>
              <!-- <div class="col-sm-12 col-md-6 col-xl-3">
                <div class="row">
                  <div class="col-sm-12 col-md-6">
                    <b-button
                      variant="primary"
                      style="height: 35px"
                      class="btn btn-block btn-sm"
                      @click="fetchPriceList"
                      >{{ $t("button.apply") }}</b-button
                    >
                  </div>
                  <div class="col-sm-12 col-md-6">
                    <b-button
                      variant="light"
                      style="height: 35px"
                      class="btn btn-block btn-sm"
                      @click="resetAdvanceFilters"
                      v-b-toggle.price-filter-collapse
                      >{{ $t("button.clear") }}</b-button
                    >
                  </div>
                </div>
              </div> -->
            </div>
            <div class="d-flex justify-content-between mt-3">
              <b-btn variant="link" @click="resetAdvanceFilters" class="text-chai font-16px">Clear Filters</b-btn>
              <div>
                <b-btn variant="primary"  v-b-toggle.price-filter-collapse  class="mr-2 cancel bg-white text-dark">Cancel</b-btn>
                <b-btn variant="primary" class="btn-padding" @click="fetchPriceList">Apply</b-btn>
              </div>
            </div>
          </b-card>
        </b-collapse>
      </div>
    </div>
    <div class="d-flex justify-content-between mb-3 mt-3">
      <search-by v-model="searchBy" :options="filterOptions" @search-by-clicked="fetchPriceList" />

        <!-- <div class="col-sm-12 col-xl-8 d-flex justify-content-end">
          <label class="mr-2" style="width: 100px; font-size:14px;"> {{ $t('common.search_by') }}</label>
          <b-form-select
            class="w-50"
            v-model="currentSelectedFilter"
            size="sm"
            :options="filterOptions"
          ></b-form-select
          >&nbsp;
          <b-input-group class="w-75">
            <b-form-input
              type="search"
              v-model="mor_filter"
              @input="fetchPriceList"
              @keyup.enter="fetchPriceList"
              class="form-control form-control-sm ml-2"
              :placeholder="$t('subscription.enter_ref')"
            ></b-form-input>
            <b-input-group-append>
              <span>
                <button
                  class="btn btn-primary btn-block btn-sm"
                  @click="fetchPriceList"
                  style="border-top-left-radius: 0px;border-bottom-left-radius: 0px;"
                >
                  {{ $t("views.payments.transactions.search") }}
                </button>
              </span>
            </b-input-group-append>
          </b-input-group>
        </div> -->

      <router-link
        :to="'/subscriptions/create-subscription-price'"
      >
        <b-btn
            v-if="!isMasterMerchant"
            variant="primary"
            size="sm"
            class="float-right"
            style="padding: 10px 16px;font-weight: 400;line-height: 20px;"
          >
            <span style="padding-right: 10px;">+</span>{{ $t('common.create_plan') }}
          </b-btn>
      </router-link>
    </div>

    <div class="">
      <b-table
        class="table-centered text-left table-custom"
        :items="priceData"
        :fields="fields"
        responsive="sm"
        :per-page="perPage"
        :small="true"
        sticky-header="50vh"
        show-empty
        :empty-text="$t('common.no-records')"
        busy.sync="true"
        hover
      >
        <template v-slot:head()="data">
          <span v-b-tooltip.hover.top :title='$t(data.field.tooltip)'>
            {{ $t(data.label) }}
          </span>
        </template>
        <template #cell(created_at)="row">
          
            <p class="table-date">{{ row.value | date }}</p>
            <p class="table-time">{{ row.value | time }}</p>
        </template>
        <template v-slot:cell(amount)="row">
          {{ row.item.plan_type === 'ONDEMAND' ? `As Per Request` : (row.value).toLocaleString('en-US', { style: 'currency', currency: row.item.currency }) }}
        </template>
        <template v-slot:cell(frequency)="row">
          {{ row.item.plan_type === 'ONDEMAND' ? `Upon Demand` : row.value }}
        </template>
        <template v-slot:cell(status)="row">
          <div
            class="badge font-size-12"
            :class="{
              'badge-soft-danger':
                `${row.value}` === 'Failed' ||
                `${row.value}` === 'Expired' ||
                `${row.value}` === 'Cancelled',
              'badge-soft-success': `${row.value}` === 'Success',
              'badge-soft-warning':
                `${row.value}` === 'Created' || `${row.value}` === 'Processing',
            }"
          >
            {{ row.value }}
          </div>
        </template>
        <template v-slot:cell(period)="row">
          {{ row.item.plan_type === 'ONDEMAND' ? `As Per Request` : cycles[row.value] }}
        </template>

        <template v-slot:cell(order_ref)="row">
          <span class="text-chai">{{row.value}}</span>
        </template>

        <template v-slot:cell(action)="row">
          <!-- <b-btn size="sm" v-if="row.item.is_active" variant="danger" @click="deletePrice(row.item)">Delete</b-btn> -->
          <div style="display: flex;align-items: center;gap: 10px; cursor: pointer;">
              <b-dropdown class="show_details" size="sm" variant="white" dropleft>
                      <template v-slot:button-content>
                      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                        <path d="M7.5 14.25C7.5 15.075 8.175 15.75 9 15.75C9.825 15.75 10.5 15.075 10.5 14.25C10.5 13.425 9.825 12.75 9 12.75C8.175 12.75 7.5 13.425 7.5 14.25ZM7.5 3.75C7.5 4.575 8.175 5.25 9 5.25C9.825 5.25 10.5 4.575 10.5 3.75C10.5 2.925 9.825 2.25 9 2.25C8.175 2.25 7.5 2.925 7.5 3.75ZM7.5 9C7.5 9.825 8.175 10.5 9 10.5C9.825 10.5 10.5 9.825 10.5 9C10.5 8.175 9.825 7.5 9 7.5C8.175 7.5 7.5 8.175 7.5 9Z" stroke="black" stroke-opacity="0.3"/>
                      </svg>
                      </template>
                    <b-dropdown-item-button @click="viewDetailsClick(row.item)" >View Details</b-dropdown-item-button>
                    <b-dropdown-item-button v-if="row.item.is_active" @click="deletePrice(row.item)">{{ $t("button.delete") }}</b-dropdown-item-button>
              </b-dropdown>
          </div>
        </template>
      </b-table>
      <Spinner v-if="isFetchingPriceData === true"></Spinner>
        <div class="d-flex justify-content-between mb-3">
            <!-- pagination -->
            <per-page
              v-model="perPage"
              :currentPage="currentPage"
              :totalRows="rows"
              :tableRowsCount="priceData.length"
              @input="fetchPriceList"
            />
            <b-pagination v-model="currentPage" :current-page="currentPage" :total-rows="rows" :per-page="perPage" @input="fetchPriceList" ></b-pagination>
        </div>
    </div>

    <!-- <div class="row">
      <div class="col">
        <div class="dataTables_paginate paging_simple_numbers float-right">
          <ul class="pagination pagination-rounded"> -->
            <!-- pagination -->
            <!-- <b-pagination
              v-model="currentPage"
              :current-page="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              @input="fetchPriceList"
            ></b-pagination>
          </ul>
        </div>
      </div>
    </div> -->

    <!-- <div
      class="right-bar"
      :class="{
        active: isAddPriceModalOpen,
      }"
      v-if="!isMasterMerchant"
    >
      <div data-simplebar class="h-100" style="overflow-x: auto">
        <div class="rightbar-title">
          <div class="right-top-section"> -->
            <!-- <img src="@/assets/images/update_config_page.png" class="cursor-pointer right-top-bg-image" alt /> -->
            <!-- <div class="card-body-custom">
              <i
                class="mdi mdi-close noti-icon float-right cursor-pointer"
                style="
                  position: relative;
                  z-index: 1;
                  right: 15px;
                  top: 15px;
                  font-size: 20px;
                "
                @click="closeCreatePriceModal"
              ></i>
              <div class="media-body media-body-custom">
                <p
                  class="d-inline-block pl-4"
                  style="font-size: 20px; margin-top: 30px; margin-bottom: 30px"
                >
                  {{ $t('common.create_plan') }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="px-4">
          <PriceForm v-if="isAddPriceModalOpen" />
        </div>
      </div> -->
      <!-- end slimscroll-menu-->
    <!-- </div> -->
    <!-- Right bar overlay-->
    <!-- <div
      v-if="!isMasterMerchant"
      @click="closeCreatePriceModal"
      class="rightbar-overlay"
      :class="{
        active: isAddPriceModalOpen,
      }"
    ></div> -->
    


     <div
      class="right-bar"
      :class="{
        active: viewDetailsModalOpen,
      }"
    >
      <div data-simplebar class="h-100" style="overflow-x: auto">
        <div class="rightbar-title">
          <div class="right-top-section">
            <div class="card-body-custom">
              <i
                class="mdi mdi-close noti-icon float-right cursor-pointer"
                style="
                  position: relative;
                  z-index: 1;
                  right: 25px;
                  top: 25px;
                  font-size: 20px;
                "
                @click="closeViewDetailsModal"
              ></i>
              <div style="padding-left: 30px; padding-bottom: 25px; padding-right: 30px;" class="media-body media-body-custom">
                <p
                  class="d-inline-block"
                  style="font-size: 18px; margin-top: 45px; margin-bottom: 0px; line-height: 24px;"
                >
                  Plan Details
                </p>
                <div style="color: rgba(0, 0, 0, 0.50);font-size: 12px;font-weight: 325;line-height: 16px;margin-top: 2px;">Check details of the selected plan.</div>
              </div>
              <div style="border-top: 1px solid rgba(220, 218, 213, 0.5); margin-bottom: 25px;"></div>
            </div>
          </div>
        </div>
        <div class="plan-view-details" style="padding-left: 30px;padding-right: 30px;">
          <!-- {{viewDetailsData}} -->
          <div class="plan-view-section">
            <div class="plan-view-heading">Plan ID</div>
            <div class="plan-view-value text-chai">{{viewDetailsData.order_ref}}</div>
          </div>
          <div class="plan-view-section">
            <div class="plan-view-heading">Payment Name</div>
            <div class="plan-view-value">{{viewDetailsData.name}}</div>
          </div>
          <div class="plan-view-section">
            <div class="plan-view-heading">Currency</div>
            <div class="plan-view-value">{{viewDetailsData.currency}}</div>
          </div>
          <div class="plan-view-section">
            <div class="plan-view-heading">Amount</div>
            <div class="plan-view-value">{{viewDetailsData.plan_type == 'ONDEMAND' ? 'As Per Request' : viewDetailsData.amount}}</div>
          </div>
           <div class="plan-view-section">
            <div class="plan-view-heading">Status</div>
            <div class="plan-view-value">
              <switch-input class="ml-auto" disableToggle :value="viewDetailsData.is_active" />
              <span style="padding-left: 14px;display: inline-block;vertical-align: middle;">{{viewDetailsData.is_active ? 'Active' : 'Deactive'}}</span>
            </div>
          </div>
          <div class="plan-view-section">
            <div class="plan-view-heading">Frequency</div>
            <div class="plan-view-value">{{ viewDetailsData.plan_type == 'ONDEMAND' ? 'Upon Demand' : `Every ${viewDetailsData.frequency} ${viewDetailsData.period}` }}</div>
          </div>
          <div class="plan-view-section">
            <div class="plan-view-heading">Plan Description</div>
            <div class="plan-view-value">{{viewDetailsData.description}}</div>
          </div>
          <div class="plan-view-section">
            <div class="plan-view-heading">Custom Fields</div>
            <div v-if="viewDetailsData.notes">
              <div class="plan-view-value d-flex" style="gap: 20px;" v-for="(note, index) in viewDetailsData.notes.notes" :key="index">
                <span>{{ note.key }}</span>
                <span>{{ note.value }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end slimscroll-menu-->
    </div>
    <!-- Right bar overlay-->
    <div
      @click="closeViewDetailsModal"
      class="rightbar-overlay"
      :class="{
        active: viewDetailsModalOpen,
      }"
    ></div>
  </div>
</template>

<style lang="css" scoped>
::v-deep .table > tbody > tr > td {
  white-space: nowrap;
}

::v-deep .table > thead > tr > th {
  white-space: nowrap;
  font-size: medium;
}

::v-deep .modal-dialog > {
  min-width: 80%;
  min-height: 80%;
}

::v-deep .modal-dial > .trxn-status {
  color: red;
}

::v-deep .input-group-addon{
   padding: 10px 10px;
    font-size: 15px;
    line-height: 1;
    text-align: center;
    background-color: #ecf0f1;
    border: 1px solid #dce4ec;
    border-left-color: transparent;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}
.right-top-section{
  /* border-bottom: 1px solid #D9D9D9; */
  /* background-color: #F7F7F7;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%; */
  position: relative;
  z-index: 1;
}
.right-bar{
  width: 500px;
  right: -500px;
}
.right-bar.active{
  right: 0px;
}
.rightbar-overlay.active{
  display: block;
  position: fixed;
}
.rightbar-title{
  color: #252B3B;
}
.right-bar .form-control,.right-bar .custom-multiselect .multiselect__tags{
  border: 1px solid #00246414;
}
.right-top-bg-image{
    position: absolute;
    right: 0px;
}
.right-bottom-bg-image{
  position: absolute;
  bottom: 0px;
}

.cursor-pointer{
  cursor: pointer;
}
@media (max-width: 700px) {
  .right-bar{
    width: 100%;
    right: -100%;
  }
}
@media (max-width: 539px) {
  .right-bar{
    width: 320px;
    right: -320px;
  }
}
::v-deep .form-control,v-deep .form-group {
  font-size: 14px;
}
::v-deep label{
  font-size: 14px;
}
::v-deep  .show_details ul{
    box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.30);
    border-radius: 12px;
    padding-top: 12px;
    padding-bottom: 12px;
    transform: translate3d(-232px, 0px, 0px) !important;
    width: 220px;

}
::v-deep   .show_details ul button{
    padding: 12px 25px;
    border-radius: 0px;
}
.plan-view-section{
  margin-bottom: 16px;
}
.plan-view-section .plan-view-heading{
    font-size: 14px;
    color: rgba(0, 0, 0, 0.30);
    line-height: 20px;
}
.plan-view-section .plan-view-value{
    font-size: 14px;
    line-height: 20px;
    padding-top: 4px;
}
</style>
